import * as actionTypes from "../../actions/wallet/actionTypes";
import { updateObject } from "../../utility/utility";

const initialState = {
	displayLoadMore: false,
	error: [],
	isLoading: false,
	isSpinning: false,
	paymentReceipt: "",
	paymentSuccessMsg: false,
	preapprovalDetails: null,
	walletSelectedCurrency: null,
	stripeResponse: null,
	topupAmount: 0,
	topupSelectedCampaignId: null,
	userWalletBalance: null,
	walletAchPaymentConfirmationId: null,
	achPaymentSpinner: false,
	walletDetails: null,
	walletDetailsLoader: false,
	childWalletDetails: null,
	childWalletDetailsLoader: false,
	walletPreapprovalId: null,
	walletTransactions: [],
	walletTransactionsLoader: false,
	withdrawSuccessMsg: false,
	childWallets: [],
	childWalletTransactions: [],
	childWalletTransactionsLoader: false,
};

const addWalletTopUpSuccess = (state, action) => {
	return updateObject(state, {
		isLoading: true,
		topupAmount: action.response.amount,
		walletPreapprovalId: action.response._id,
	});
};

const addChildWalletTopUpSuccess = (state, action) => {
	return updateObject(state, {
		isLoading: true,
		topupAmount: action.response.amount,
		walletPreapprovalId: action.response._id,
	});
};

const createWalletOfflinePaymentSuccess = (state, action) => {
	return updateObject(state, {
		paymentSuccessMsg: action.response?.payType === "stripe" ? false : true,
		paymentReceipt: action.response?.paymentReceipt,
		isLoading: false,
		isSpinning: action.response?.payType === "stripe" ? true : false,
		preapprovalDetails: null,
		achPaymentSpinner: false,
		error: [],
	});
};

const getWalletDetailsSuccess = (state, action) => {
	return updateObject(state, {
		walletDetails: action.response,
		walletDetailsLoader: false,
		topupSelectedCampaignId: null,
		childWalletDetails: null,
		childWalletDetailsLoader: false,
	});
};

const getChildWalletDetailsSuccess = (state, action) => {
	return updateObject(state, {
		childWalletDetails: action.response,
		childWalletDetailsLoader: false,
		topupSelectedCampaignId: null,
		walletDetailsLoader: false,
	});
};

const getWalletTransactionsSuccess = (state, action) => {
	return updateObject(state, {
		displayLoadMore: action.response?.displayLoadMore,
		userWalletBalance: action.response?.userWalletBalance,
		walletDetailsLoader: false,
		walletTransactions: action.response?.docs,
		paymentSuccessMsg: false,
		walletTransactionsLoader: false,
		childWalletTransactionsLoader: false,
		isSpinning: false,
		paymentReceipt: "",
		error: [],
	});
};

const getWalletPreapprovalDetailsSuccess = (state, action) => {
	return updateObject(state, {
		preapprovalDetails: action.response,
		paymentReceipt: "",
	});
};

const resetTopupPaymentData = (state, action) => {
	return updateObject(state, {
		paymentSuccessMsg: false,
		isSpinning: false,
		preapprovalDetails: false,
		stripeResponse: null,
		stripeResponse: null,
		topupAmount: 0,
		walletAchPaymentConfirmationId: null,
	});
};

const setWalletSelectedCurrency = (state, action) => {
	return updateObject(state, {
		walletSelectedCurrency: action.currency,
	});
};

const setSelectedCampaignId = (state, action) => {
	return updateObject(state, {
		topupSelectedCampaignId: action.campaignId,
	});
};

const postWalletACHPaymentSuccess = (state, action) => {
	return updateObject(state, {
		walletAchPaymentConfirmationId: action.response,
	});
};

const updateWalletTopupSuccess = (state, action) => {
	return updateObject(state, {
		paymentSuccessMsg: action.response === "stripe" ? false : true,
	});
};

const walletFail = (state, action) => {
	return updateObject(state, {
		error: action.response,
		isSpinning: false,
		stripeResponse: null,
		paymentSuccessMsg: false,
		walletDetailsLoader: false,
		childWalletDetailsLoader: false,
		childWalletTransactionsLoader: false,
		walletAchPaymentConfirmationId: null,
		achPaymentSpinner: false,
	});
};

const walletPaymentStart = (state, action) => {
	return updateObject(state, {
		paymentReceipt: "",
		paymentSuccessMsg: false,
		isSpinning: true,
		withdrawSuccessMsg: false,
		isLoading: false,
		stripeResponse: null,
		walletAchPaymentConfirmationId: null,
		achPaymentSpinner: true,
	});
};

const walletStripePaymentInitiateSuccess = (state, action) => {
	return updateObject(state, {
		stripeResponse: action.response,
	});
};

const walletStart = (state, action) => {
	return updateObject(state, {
		error: null,
		isLoading: false,
		walletPreapprovalId: null,
		walletDetailsLoader: action.pageLimit === "none" ? false : true,
		childWalletDetailsLoader: action.pageLimit === "none" ? false : true,
		childWalletTransactionsLoader: action.pageLimit === "none" ? false : true,
		walletTransactionsLoader: action.pageLimit === 1 ? true : false,
		isSpinning: action.pageLimit > 1 ? true : false,
		...(action.pageLimit <= 1 && {
			walletTransactions: [],
			childWalletTransactions: [],
		}),
		stripeResponse: null,
	});
};

const getChildWalletList = (state, action) => {
	return updateObject(state, {
		childWallets: action.response,
		walletDetailsLoader: false,
		childWalletDetailsLoader: false,
		isSpinning: false,
		isLoading: false,
		error: [],
	});
};

const getChildWalletTransactions = (state, action) => {
	return updateObject(state, {
		displayLoadMore: action.response?.displayLoadMore,
		childWalletTransactionsLoader: false,
		childWalletTransactions: action.response,
		walletDetailsLoader: false,
		childWalletDetailsLoader: false,
		isSpinning: false,
		isLoading: false,
	});
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.ADD_WALLET_TOP_UP_SUCCESS:
			return addWalletTopUpSuccess(state, action);
		case actionTypes.ADD_CHILD_WALLET_TOP_UP_SUCCESS:
			return addChildWalletTopUpSuccess(state, action);
		case actionTypes.CREATE_WALLET_OFFLINE_PAYMENT_SUCCESS:
			return createWalletOfflinePaymentSuccess(state, action);
		case actionTypes.GET_WALLET_DETAILS_SUCCESS:
			return getWalletDetailsSuccess(state, action);
		case actionTypes.GET_CHILD_WALLET_DETAILS_SUCCESS:
			return getChildWalletDetailsSuccess(state, action);
		case actionTypes.GET_WALLET_PREAPPROVAL_DETAILS_SUCCESS:
			return getWalletPreapprovalDetailsSuccess(state, action);
		case actionTypes.GET_WALLET_TRANSACTIONS_SUCCESS:
			return getWalletTransactionsSuccess(state, action);
		case actionTypes.RESET_TOPUP_PAYMENT_DATA:
			return resetTopupPaymentData(state, action);
		case actionTypes.SET_WALLET_SELECTED_CURRENCY:
			return setWalletSelectedCurrency(state, action);
		case actionTypes.SET_SELECTED_CAMPAIGN_ID:
			return setSelectedCampaignId(state, action);
		case actionTypes.POST_WALLET_ACH_PAYMENT_SUCCESS:
			return postWalletACHPaymentSuccess(state, action);
		case actionTypes.UPDATE_WALLET_TOPUP_SUCCESS:
			return updateWalletTopupSuccess(state, action);
		case actionTypes.WALLET_FAIL:
			return walletFail(state, action);
		case actionTypes.WALLET_PAYMENT_START:
			return walletPaymentStart(state, action);
		case actionTypes.WALLET_STRIPE_PAYMENT_INITIATE_SUCCESS:
			return walletStripePaymentInitiateSuccess(state, action);
		case actionTypes.WALLET_START:
			return walletStart(state, action);
		case actionTypes.GET_CHILD_WALLET_LIST_SUCCESS:
			return getChildWalletList(state, action);
		case actionTypes.GET_CHILD_WALLET_TRANSACTIONS_SUCCESS:
			return getChildWalletTransactions(state, action);
		default:
			return state;
	}
};
export default reducer;
